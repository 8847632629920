import React from 'react'
import PageHeading from '../components/headings/PageHeading'
import FreeTrialContent from '../components/bricks/FreeTrialContent'
import QuoteAlphr from '../components/quotes/QuoteAlphr'

const FreeTrialView = ({ formik }) => {
  return (
    <>
      <PageHeading
        title="free-trial:page-title"
        subtitle="free-trial:page-subtitle"
      />
      <FreeTrialContent
        formik={formik}
        className="top-triangle-divider is-clear"
      />
      <QuoteAlphr />
    </>
  )
}

export default FreeTrialView
