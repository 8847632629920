import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'

const NumberList = ({ items }) => {
  const t = useTranslations()
  return (
    <ul className="number-list">
      {items.map(item => (
        <li className="number-list-item" key={item.text}>
          <span className="number-list-text">{t(item.text)}</span>
        </li>
      ))}
    </ul>
  )
}

NumberList.propTypes = {
  items: PropTypes.array,
}

export default NumberList
