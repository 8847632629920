import React from 'react'
import MainLayout from '../layouts/MainLayout'
import FreeTrialView from '../views/FreeTrialView'

const FreeTrialPage = ({ formik }) => {
  return (
    <MainLayout className="free-trial">
      <FreeTrialView formik={formik} />
    </MainLayout>
  )
}

export default FreeTrialPage
