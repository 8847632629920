import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import useTranslations from '../../../hooks/useTranslations'

const Radio = ({
  onChange,
  textError,
  textLabel,
  name,
  checked,
  isDisabled,
  children,
}) => {
  const classes = cx('radio', {
    'has-error': textError,
    'is-disabled': isDisabled,
  })
  const t = useTranslations()

  return (
    <div className={classes}>
      <input
        className="radio-input"
        type="radio"
        id={textLabel}
        name={name}
        onChange={onChange}
        checked={checked}
      />
      <div className="radio-text">
        <label className="radio-label" htmlFor={textLabel}>
          <span className="radio-labelText">{t(textLabel)}</span>
          {React.Children.count(children) ? children : null}
        </label>
      </div>
      {textError ? <span className="error-text">{t(textError)}</span> : null}
    </div>
  )
}

Radio.propTypes = {
  onChange: PropTypes.func,
  children: PropTypes.node,
  textError: PropTypes.string,
  textLabel: PropTypes.string.isRequired,
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
}

export default Radio
