import React from 'react'
import useTranslations from '../../../hooks/useTranslations'
import NumberList from '../lists/NumberList'
import RegisterForm from '../../components/forms/RegisterForm'
import useScrollRevealEffect from '../../../hooks/effects/useScrollRevealEffect'

const items = [
  {
    text: 'free-trial:item1',
  },
  {
    text: 'free-trial:item2',
  },
  {
    text: 'free-trial:item3',
  },
  {
    text: 'free-trial:item4',
  },
]

const FreeTrialContent = ({ className, formik }) => {
  const t = useTranslations()
  const blockRef = useScrollRevealEffect()
  const block2Ref = useScrollRevealEffect()

  return (
    <div className={className}>
      <div className="free-trial-content wrapper" ref={blockRef}>
        <div className="free-trial-list">
          <h2 className="free-trial-title">{t('free-trial:how-it-works')}</h2>
          <NumberList items={items} />
        </div>
        <div className="free-trial-form" ref={block2Ref}>
          <RegisterForm formik={formik} />
        </div>
      </div>
    </div>
  )
}

export default FreeTrialContent
