import React from 'react'
import useTranslations from '../../../hooks/useTranslations'
import Input from '../form-elements/Input'
import Select from '../form-elements/Select'
import Checkbox from '../form-elements/Checkbox'
import Radio from '../form-elements/Radio'
import Button from '../buttons/Button'
import Loader from '../loader/Loader'
import RadioGroup from '../form-elements/RadioGroup'

const options = [
  {
    name: 'register:which-department',
    value: 'no-selection',
  },
  {
    name: 'sales:name',
    value: 'sales',
  },
  {
    name: 'register:service',
    value: 'service',
  },
  {
    name: 'noun:training',
    value: 'training',
  },
  {
    name: 'register:support',
    value: 'support',
  },
  {
    name: 'register:other',
    value: 'other',
  },
]

const RegisterForm = ({ formik }) => {
  const t = useTranslations()
  return (
    <div className="register-form-wrapper">
      {formik.isSubmitting ? (
        <Loader color="#0092ff" size="60px" />
      ) : (
        <>
          <h2 className="register-form-title">{t('register:title')}</h2>
          <p className="register-form-subtitle">
            {t('register:create-account')}
          </p>
          <form className="register-form">
            <Input
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              type="text"
              textError={formik.errors.name && t(formik.errors.name)}
              hasError={Boolean(formik.touched.name && formik.errors.name)}
              placeholder="form-label:first-name"
              literal
              id="register_name"
            />
            <Input
              name="last_name"
              onChange={formik.handleChange}
              value={formik.values.last_name}
              type="text"
              textError={formik.errors.last_name && t(formik.errors.last_name)}
              hasError={Boolean(
                formik.touched.last_name && formik.errors.last_name,
              )}
              placeholder="form-label:last-name"
              literal
              id="register_last_name"
            />
            <Input
              name="company"
              onChange={formik.handleChange}
              value={formik.values.company}
              type="text"
              textError={formik.errors.company && t(formik.errors.company)}
              hasError={Boolean(
                formik.touched.company && formik.errors.company,
              )}
              placeholder="form-label:company-name"
              id="register_company"
            />
            <Select
              name="department"
              options={options}
              value={formik.values.department}
              size="normal"
              onChange={formik.handleChange}
              id="department"
            />
            <RadioGroup
              globalLabel="register:access-cad-3d-data"
              textError={
                formik.errors.access_cad_3d_data &&
                t(formik.errors.access_cad_3d_data)
              }
            >
              <Radio
                name="access_cad_3d_data"
                textLabel="answer:yes"
                checked={formik.values.access_cad_3d_data === 'yes'}
                onChange={e => {
                  formik.setFieldValue('access_cad_3d_data', 'yes')
                }}
              />
              <Radio
                name="access_cad_3d_data"
                textLabel="answer:no"
                checked={formik.values.access_cad_3d_data === 'no'}
                onChange={e => {
                  formik.setFieldValue('access_cad_3d_data', 'no')
                }}
              />
            </RadioGroup>
            <Input
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              type="text"
              textError={formik.errors.email && t(formik.errors.email)}
              hasError={Boolean(formik.touched.email && formik.errors.email)}
              placeholder="form-label:email"
              id="register_email"
            />

            <Input
              name="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              type="password"
              textError={formik.errors.password && t(formik.errors.password)}
              hasError={Boolean(
                formik.touched.password && formik.errors.password,
              )}
              placeholder="form-label:password"
              id="register_password"
            />

            <Checkbox
              name="personal_onboarding"
              textLabel="register:personal-onboarding"
              checked={formik.values.personal_onboarding}
              onChange={e => {
                formik.setFieldValue('personal_onboarding', e.target.checked)
              }}
              hasError={Boolean(
                formik.touched.personal_onboarding &&
                  formik.errors.personal_onboarding,
              )}
              textError={
                formik.errors.personal_onboarding &&
                t(formik.errors.personal_onboarding)
              }
            ></Checkbox>

            <Checkbox
              name="reach_out"
              textLabel="register:agree-terms"
              checked={formik.values.reach_out}
              onChange={e => {
                formik.setFieldValue('reach_out', e.target.checked)
              }}
              hasError={Boolean(
                formik.touched.reach_out && formik.errors.reach_out,
              )}
              textError={formik.errors.reach_out && t(formik.errors.reach_out)}
            >
              <a
                className="link default"
                href="/data-privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('register:terms-and-conditions')}
              </a>
            </Checkbox>
            <Button
              text="register:sign-up"
              type="submit"
              as="button"
              onClick={formik.handleSubmit}
              size="large"
            />
            {/*
            <LinkAnchor
              text="register:already-have-account"
              href="https://manager.aucta.io/auth/login"
              isExternal
            />*/}
          </form>
        </>
      )}
    </div>
  )
}

export default RegisterForm
