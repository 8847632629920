import React, { forwardRef } from 'react'
import cx from 'classnames'
import useTranslations from '../../../hooks/useTranslations'

const PageHeading = forwardRef(
  ({ title, isBig, subtitle, hasDivider, className }, ref) => {
    const t = useTranslations()
    return (
      <div
        ref={ref}
        className={cx('page-heading', {
          'is-big': isBig,
          'has-divider': hasDivider,
          [className]: className,
        })}
      >
        <h1 className="page-heading-title">
          {t(title, {
            span: t => <span className="emphasis-text">{t}</span>,
          })}
        </h1>
        {subtitle ? (
          <p className="page-heading-subtitle">{t(subtitle)}</p>
        ) : null}
      </div>
    )
  },
)

export default PageHeading
