import React from 'react'
import PropTypes from 'prop-types'
import FormField from './FormField'
import useTranslations from '../../../hooks/useTranslations'
import arrow from '../../../assets/images/arrow.svg'
import cx from 'classnames'

const Select = ({
  options,
  isDisabled,
  onBlur,
  onChange,
  label,
  textError,
  value,
  name,
  id,
  size,
}) => {
  const t = useTranslations()
  const classes = cx('select', {
    [size]: size,
  })
  return (
    <FormField isDisabled={isDisabled} label={label} textError={textError}>
      <div className="select-wrapper">
        <select
          className={classes}
          id={id}
          onBlur={onBlur}
          onChange={onChange}
          name={name}
          value={value}
        >
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {t(option.name)}
            </option>
          ))}
        </select>
        <img className="select-arrow" alt="arrow" src={arrow} />
      </div>
    </FormField>
  )
}

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  id: PropTypes.string,
  isDisabled: FormField.propTypes.isDisabled,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  label: FormField.propTypes.label,
  value: PropTypes.string,
  textError: FormField.propTypes.textError,
  size: PropTypes.oneOf(['normal', 'narrow']),
}

Select.defaultProps = {
  size: 'normal',
}

export default Select
