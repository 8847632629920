import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import useTranslations from '../../../hooks/useTranslations'
import { ReactComponent as Check } from '../../../assets/icons/icon-check.svg'

const Checkbox = ({
  onChange,
  textError,
  textLabel,
  name,
  checked,
  isDisabled,
  children,
  hasError = true,
  className,
}) => {
  const classes = cx('checkbox', {
    'has-error': hasError && textError,
    'is-disabled': isDisabled,
    [className]: className,
  })
  const t = useTranslations()

  return (
    <div className={classes}>
      <input
        className="checkbox-input"
        type="checkbox"
        id={textLabel}
        name={name}
        onChange={onChange}
        checked={checked}
      />
      <Check className="checkbox-icon" />
      <div className="checkbox-text">
        <label className="checkbox-label" htmlFor={textLabel}>
          <span className="checkbox-labelText">{t(textLabel)}</span>
          {React.Children.count(children) ? children : null}
        </label>
      </div>
      {hasError && textError ? (
        <span className="error-text">{t(textError)}</span>
      ) : null}
    </div>
  )
}

Checkbox.propTypes = {
  onChange: PropTypes.func,
  children: PropTypes.node,
  textError: PropTypes.string,
  textLabel: PropTypes.string.isRequired,
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
}

export default Checkbox
