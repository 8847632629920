import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import useTranslations from '../../../hooks/useTranslations'

const RadioGroup = ({ globalLabel, textError, isDisabled, children }) => {
  const classes = cx('radio-group', {
    'has-error': textError,
    'is-disabled': isDisabled,
  })
  const t = useTranslations()

  return (
    <div className={classes}>
      <p className="global-label">{t(globalLabel)}</p>
      <div className="radios">{children}</div>
      {textError ? <span className="error-text">{t(textError)}</span> : null}
    </div>
  )
}

RadioGroup.propTypes = {
  children: PropTypes.node,
  textError: PropTypes.string,
  globalLabel: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
}

export default RadioGroup
